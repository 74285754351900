<template>
  <div class="personApply_container">
    <div class="personApply_container_top_bar">
      <h1 class="personApply_container_top_bar_tilte">INDUVIDUAL & SOLE TRADER & 个体经营</h1>
      <h1 class="personApply_container_top_bar_subtilte">APPLICATION FORM 申请表</h1>
    </div>
    <a-tabs
      :default-active-key="getCurrentTabIndex"
      :activeKey="getCurrentTabIndex"
      tab-position="top"
      :style="{ height: '80px' }"
      @prevClick="callback"
      @nextClick="callback"
      size="large"
      type="line"
      :tabBarGutter="20"
      @tabClick="handleTabClick"
    >
        <a-tab-pane style="height: 0" key="1">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">1</div> APPLICANT DETAILS 个人信息</div>
        </template>
      </a-tab-pane>
      <a-tab-pane key="2">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">2</div> ACCOUNT BEHAVIOUR BASELINE 账户行为基准</div>
        </template>
      </a-tab-pane>
      <a-tab-pane key="3">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">3</div>AUTHORIZED PERSON 授权人</div>
        </template>
      </a-tab-pane>
      <a-tab-pane key="4">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">4</div>ACKNOWLEDEMENT 责任书</div>
        </template>
      </a-tab-pane>
      <a-tab-pane
        key="5"
      >
      <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">5</div>“PEP" SELF-CERTIFICATION PEP自我认证</div>
        </template>
      </a-tab-pane>
      <!-- <a-tab-pane key="6">
      <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">6</div>AML PoT / SoF</div>
        </template>
      </a-tab-pane> -->
      <!-- <a-tab-pane key="7">
        <template slot='tab'>
          <div class="tabbar_slot"><div class="tabbar_circle">7</div>Uploads</div>
        </template>
      </a-tab-pane> -->
    </a-tabs>
    <keep-alive>
    <router-view :key='$route.fullPath'/>
    </keep-alive>
    <div class="button_wrapper">
      <button v-if='isPreviewBtnShow' @click="handleToPrevBtn"  class="btn_lay pre_btn">PREVIOUS
      </button>
      <div v-else></div>
      <button v-if="isNextBtnShow" @click="handleToNextStep" class="btn_lay next_btn">NEXT
      </button>
      <button
      @click="handleSubmit"
      v-else class="btn_lay next_btn">SUBMIT
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
// import axios from 'axios'

export default {
  data () {
    return {
      tabarIndex: 1,
      mode: 'top',
      fileUrl: []
    }
  },
  mounted () {
  },
  created () {
    this.fetchCountryList()
  },
  computed: {
    ...mapState('personStepSeven', ['fileList']),
    ...mapGetters([
      'getAllPersonFormData', 'getFileList'
    ]),
    isNextBtnShow () {
      if (this.$route.path.split('/')[3] && this.$route.path.split('/')[3] < 5) {
        return true
      }
      return false
    },
    isPreviewBtnShow () {
      if (this.$route.path.split('/')[3] && this.$route.path.split('/')[3] > 1) {
        return true
      }
      return false
    },
    getCurrentTabIndex () {
      const tabIndex = this.$route.path.split('/')[3]
      return tabIndex
    }
  },
  methods: {
    ...mapActions({
      fetchCountryList: 'countrySelection/FetchDataAction'
    }),
    handleToNextStep () {
      const nextTabIndex = +this.getCurrentTabIndex + 1
      this.$router.push('/user/personapplication/' + nextTabIndex)
    },
    handleToPrevBtn () {
      const nextTabIndex = +this.getCurrentTabIndex - 1
      this.$router.push('/user/personapplication/' + nextTabIndex)
    },
    callback (val) {
      console.log(val)
    },
    handleTabClick (e) {
      if (e === this.getCurrentTabIndex) {
        return false
      }
      this.$router.push('/user/personapplication/' + e)
    },
    handleSubmit () {
      console.log(this.getAllPersonFormData)
      const temp = []
      for (const x of this.fileList) {
        // this.fileUrl = this.fileUrl.push(x.response)
        temp.push(x.response)
      }
      // axios({
      //   method: 'post',
      //   url: 'https://fx.iemoney.co.nz/api/submit-cdd',
      //   data: { ...this.getAllPersonFormData, fileUrl: temp.join('|||') }
      // })
    }
  }

}
</script>

<style scoped>
.tabbar_slot{
  display: flex;
  align-items: center;
}
.tabbar_circle{
  width: 28px;
  margin-right: 10px;
  height: 28px;
  border-radius: 50%;
  font-weight: 500;
  border: 2px solid #ea7840;
  display: flex;
  align-items: center;
  justify-content: center;
}
.personApply_container_top_bar_tilte{
  font-size: 24px;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container_top_bar_subtilte{
  font-size: 35px;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container{
  min-height: 100vh;
  background: rgb(248,248 , 248);
  padding-bottom: 30px;
}
.button_wrapper{
  max-width: 700px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}
.personApply_container_top_bar{
  background: #373737;
  height: 170px;
  padding-top: 30px;
}
.btn_lay{
  width: 240px;
  height: 60px;
  border: 1px solid #ea7840;
  background: #fff;
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 300;
  color: #ea7840;
  transition: .2s;
  outline: none;
}
.btn_lay:hover{
  background: #ea7840;
  color: #fff;

}
.pre_btn:disabled{
  cursor: not-allowed;
}
.next_btn{
   background: #ea7840;
  color: #fff;
}
@media only screen and (max-width: 768px) {
.personApply_container_top_bar_tilte{
  font-size: 4.6vw;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container_top_bar_subtilte{
  font-size: 6vw;
  margin-top: 2vh;
  text-align: center;
  color: #fff;
  font-weight: 300;
}
.personApply_container_top_bar{
  background: #373737;
  height: 20vh;
  padding-top: 30px;
}
.btn_lay{
  width: 36vw;
  height: 8vh;
  border: 1px solid #ea7840;
  background: #fff;
  font-size: 4.6vw;
  letter-spacing: 1px;
  font-weight: 300;
  color: #ea7840;
  transition: .2s;
  outline: none;
}
.button_wrapper{
  padding: 0 3vw;
}
.personApply_container{
  padding-bottom: 1px;
}
}
</style>
